import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';

import { SearchReducer } from '../feature/reducers/search';
import { MailReducer } from '../feature/reducers/mail';

export const reducers = combineReducers({
    search: SearchReducer,
    mail: MailReducer,
});

export type AppState = StateType<typeof reducers>;
