import { ActionType, getType, Reducer } from "typesafe-actions";

import * as actions from '../actions';
import { getInitialSearchState } from "../states";
import { SearchState } from "../types";

type Actions = ActionType<typeof actions>;

export const SearchReducer: Reducer<SearchState, Actions> = (state = getInitialSearchState(), action) => {
    switch (action.type) {

        case getType(actions.searchResults.success):
            const sortedRes = action.payload.sort((a, b) => a.answer.localeCompare(b.answer));
            return {
                ...state,
                result: [...sortedRes],
            };

        case getType(actions.searchResults.failure):
            return {
                ...state,
                failureMessage: action.payload,
            };

        default:
            return state;
    }
};
