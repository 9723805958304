import { ActionType, getType, Reducer } from "typesafe-actions";

import * as actions from '../actions';
import { MailState } from "../types";

type Actions = ActionType<typeof actions>;

export const MailReducer: Reducer<MailState, Actions> = (state = {mail: '', failureMessage: ''}, action) => {
    switch (action.type) {

        case getType(actions.setMail.success):
            return {
                ...state,
                mail: action.payload,
            };

        case getType(actions.setMail.failure):
            return {
                ...state,
                failureMessage: action.payload,
            };

        default:
            return state;
    }
};
