import { AUTH_URL } from "../urls";

export const getAuthRequest = async (): Promise<Response> => {

    const headers = {
        'Content-Type': 'application/json',
    };

    return await fetch(AUTH_URL, {
        headers: headers,
        method: 'GET',
    });
};
