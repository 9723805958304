import { call, put, takeLatest } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";

import { deleteOne, editOne, searchOne, searchResults } from "../actions";
import { getDeleteRequest, getEditRequest, getResultsRequest, getSearchRequest } from "../api";
import { ResponceItem } from "../types";

export function* requestSearchResults({ payload }: ActionType<typeof searchResults.request>) {
    try {
        const responce:Response = yield call(getResultsRequest);
        const results: Array<ResponceItem> = yield responce.json();
        
        yield put(searchResults.success(results));
    } catch (e) {
        yield put(searchResults.failure('error search'));
    }
}

export function* requestSearchOne({ payload }: ActionType<typeof searchOne>) {
    try {
        yield call(getSearchRequest, payload);
        const responce:Response = yield call(getResultsRequest);
        const results: Array<ResponceItem> = yield responce.json();

        yield put(searchResults.success(results));
    } catch (e) {
        yield put(searchResults.failure('error search'));
    }
}

export function* requestDeleteOne({ payload }: ActionType<typeof deleteOne>) {
    try {
        yield call(getDeleteRequest, payload);
        const responce:Response = yield call(getResultsRequest);
        const results: Array<ResponceItem> = yield responce.json();
        
        yield put(searchResults.success(results));
    } catch (e) {
        yield put(searchResults.failure('error search'));
    }
}

export function* requestEditOne({ payload }: ActionType<typeof editOne>) {
    try {
        yield call(getEditRequest, payload);
        const responce:Response = yield call(getResultsRequest);
        const results: Array<ResponceItem> = yield responce.json();
        
        yield put(searchResults.success(results));
    } catch (e) {
        yield put(searchResults.failure('error search'));
    }
}

export function* watchSearchResults() {
    yield takeLatest(getType(searchResults.request), requestSearchResults);
}

export function* watchSearchOne() {
    yield takeLatest(getType(searchOne), requestSearchOne);
}

export function* watchEditOne() {
    yield takeLatest(getType(editOne), requestEditOne);
}

export function* watchDeleteOne() {
    yield takeLatest(getType(deleteOne), requestDeleteOne);
}