import React from 'react';
import { useDispatch } from 'react-redux';

import clasess from './feature/styles/app.module.scss'

import { SearchArea } from './feature/components/SearchArea';
import { searchResults, initMail } from './feature/actions';

const App: React.FC = () => {
    const dispatch = useDispatch();

    dispatch(initMail());
    dispatch(searchResults.request());

    return (
        <div className={clasess.app}>
            <SearchArea />
        </div>
    );
}

export default App;
