import { all } from 'redux-saga/effects';

import { watchAuth } from '../feature/sagas/auth';
import { watchEditOne, watchSearchResults, watchSearchOne, watchDeleteOne } from '../feature/sagas/search';
import { watchInitMail, watchSetMail } from '../feature/sagas/mail';

export function* rootSaga() {
    yield all([
        watchAuth(),
        watchSearchResults(),
        watchSearchOne(),
        watchEditOne(),
        watchDeleteOne(),
        watchInitMail(),
        watchSetMail(),
    ]);
}
