import { call, put, takeLatest } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import { auth } from "../actions";
import { getAuthRequest } from "../api";

export function* requestAuth({ payload }: ActionType<typeof auth.request>) {
    try {
        yield call(getAuthRequest);
        yield put(auth.success());
    } catch (e) {
        yield put(auth.failure('error auth'));
    }
}

export function* watchAuth() {
    yield takeLatest(getType(auth.request), requestAuth);
}
