import { INIT_MAIL_URL, MAIL_URL } from "../urls";

export const getMailRequest = async (mail: string): Promise<Response> => {

    const headers = {
        'Content-Type': 'application/json',
    };

    return await fetch(MAIL_URL, {
        headers: headers,
        method: 'POST',
        body: JSON.stringify({ eMail: mail }),
    });
};

export const getInitMailRequest = async (): Promise<Response> => {

    const headers = {
        'Content-Type': 'application/json',
    };

    return await fetch(INIT_MAIL_URL, {
        headers: headers,
        method: 'GET',
    });
};