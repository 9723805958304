import { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classes from './filterArea.module.scss';
import classesSearch from './searchArea.module.scss';
import { setMail } from '../actions';
import { getMail } from '../selectors';

interface FilterProps {
    handleChangeTime: (event: ChangeEvent<HTMLInputElement>) => void,
}

export const FilterArea: React.FC<FilterProps> = ({handleChangeTime}) => {

    const defaultTime = 2;
    const [mailAddres, setMailAddres] = useState('');
    const { mail } = useSelector(getMail);
    const dispatch = useDispatch();

    const onTimeChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if(event.target.value.length === 0) {
            return
        }

        if(Number(event.target.value) > 60 ) {
            event.target.value = '60';
        }

        if(Number(event.target.value) < 1 ) {
            event.target.value = '1';
        }

        handleChangeTime(event);
    }, [handleChangeTime]);

    const onMailChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMailAddres(event.target.value);
    }, []);

    const onSubmitMail = useCallback((event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(setMail.request(mailAddres));
        event.currentTarget.reset();
    }, [dispatch, mailAddres]);

    return (
        <div className={classes.filters}>
            Search every
            <input 
                type='number' 
                className={classes.filters__time}
                min={1}
                max={60}
                onChange={onTimeChange} 
                defaultValue={defaultTime}
            />
            min.
            
            <form onSubmit={onSubmitMail} className={classes.form}>
                E-mail:
                <input 
                    type='email'
                    className={classes.filters__email}
                    onChange={onMailChange}
                    placeholder='Your Email *'
                    required
                />
                <button type='submit' className={classesSearch.button}>Submit E-mail</button>
                current: {mail}
            </form>
        </div>
    )
}