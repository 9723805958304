import { EditItem } from "../types";
import { SEARCH_URL, RESULTS_URL, DELETE_URL, EDIT_URL } from "../urls";

export const getSearchRequest = async (message: string): Promise<Response> => {

    const headers = {
        'Content-Type': 'application/json',
    };

    return await fetch(SEARCH_URL, {
        headers: headers,
        method: 'POST',
        body: JSON.stringify({ message: message}),
    });
};

export const getResultsRequest = async (): Promise<Response> => {

    const headers = {
        'Content-Type': 'application/json',
    };

    return await fetch(RESULTS_URL, {
        headers: headers,
        method: 'GET',
    });
};

export const getDeleteRequest = async (id: string): Promise<Response> => {

    const headers = {
        'Content-Type': 'application/json',
    };

    return await fetch(DELETE_URL + id, {
        headers: headers,
        method: 'DELETE',
    });
};

export const getEditRequest = async (message: EditItem): Promise<Response> => {
    
    const headers = {
        'Content-Type': 'application/json',
    };

    return await fetch(EDIT_URL, {
        headers: headers,
        method: 'POST',
        body: JSON.stringify({ newAnsver: message.newAnsver, oldAnsver: message.oldAnsver, id: message.ID }),
    });
};