import { createAction, createAsyncAction } from "typesafe-actions";

import { EditItem, ResponceItem } from "../types";

export const searchResults = createAsyncAction(
    'feature/search/SEARCH_RESULTS_REQUEST',
    'feature/search/SEARCH_RESULTS_SUCCESS',
    'feature/search/SEARCH_RESULTS_FAILURE',
)<void, Array<ResponceItem>, string>();

export const searchOne = createAction(
    'feature/search/SEARCH_ONE_REQUEST'
)<string>();

export const deleteOne = createAction(
    'feature/search/DELETE_REQUEST'
)<string>();

export const editOne = createAction(
    'feature/search/EDIT_REQUEST',
)<EditItem>();