import React, { ChangeEvent, FormEvent, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classes from './searchArea.module.scss';

import { deleteOne, editOne, searchOne, searchResults } from '../actions';
import { getSearch } from '../selectors';
import { FindedItem } from './FindedItem';
import { SearchInput } from './SearchInput';
import { FilterArea } from './FilterArea';

export const SearchArea: React.FC = () => {

    const [searchTime, setSearchTime] = useState(120000);

    const { result } = useSelector(getSearch);
    
    const dispatch = useDispatch();

    const handleSubmitSearch = useCallback((event: FormEvent<HTMLFormElement>, searchAnsver: string) => {
        event.preventDefault();
        if(searchAnsver.length > 0) {
            if(result.find((item) => {return item.answer === searchAnsver})) {
                return
            }
            
            dispatch(searchOne(searchAnsver));
        }
        event.currentTarget.reset();
    }, [dispatch, result]);

    const handleDelete = useCallback((event: MouseEvent) => {
        dispatch(deleteOne(event.currentTarget.id));
    }, [dispatch]);

    const handleEdit = useCallback((event: FormEvent<HTMLFormElement>, ansver: string, ansverOld: string, ID: number) => {
        event.preventDefault();
        dispatch(editOne({newAnsver: ansver, oldAnsver: ansverOld, ID: ID}));
    }, [dispatch]);

    const handleChangeTime = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSearchTime(Number(event.target.value) * 60000);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if(result && result.length > 0) {
                dispatch(searchResults.request());
            }
        }, searchTime);

        return () => clearInterval(interval);
    }, [dispatch, result, searchTime]);

    return (
        <div className={classes.container}>
            <h1>Search area</h1>
            <div className={classes.container__search}>
                <SearchInput
                    handleSubmit={handleSubmitSearch}
                />
            </div>
            <div className={classes.container__filter}>
                <FilterArea handleChangeTime={handleChangeTime}/>
            </div>
            <div className={classes.container__results}>
                {
                    result && result.length > 0
                    ?
                    (result && result.map((item) => {
                        return <FindedItem
                            ID={item.id}
                            oldAnsver={item.answer}
                            key={item.answer + item.id} 
                            items={item.resultdata}
                            handleDelete={handleDelete}
                            handleEditSearch={handleEdit}
                        />
                    }))
                    :
                    <></>
                }
            </div>
        </div>
    );
}