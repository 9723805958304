import { ActionType, getType } from "typesafe-actions";
import { call, put, takeLatest } from "redux-saga/effects";

import { setMail, initMail } from "../actions";
import { getInitMailRequest, getMailRequest } from "../api";
import { MailState } from "../types";

export function* requestMail({ payload }: ActionType<typeof setMail>) {
    try {
        const responce:Response = yield call(getMailRequest, payload);
        const result:MailState = yield responce.json();
        yield put(setMail.success(result.mail));
    } catch (e) {
        yield put(setMail.failure('error set e-mail'));
    }
}

export function* watchSetMail() {
    yield takeLatest(getType(setMail.request), requestMail);
}

export function* initMailRequest({ payload }: ActionType<typeof initMail>) {
    try {
        const responce:Response = yield call(getInitMailRequest);
        const result:MailState = yield responce.json();
        yield put(setMail.success(result.mail));
    } catch (e) {
        yield put(setMail.failure('error set e-mail'));
    }
}

export function* watchInitMail() {
    yield takeLatest(getType(initMail), initMailRequest);
}